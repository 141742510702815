<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页设置<span>&gt;</span>轮播图管理<span>&gt;</span>
			新增轮播图
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/carousel/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<table style="width:400px;margin-bottom:10px;margin-top:20px;margin-left:20px;">
				<tr>
					<td style="width: 94px;margin-bottom: 15px;vertical-align: text-bottom;">
						<label class="el-form-item__label" style="width: 100%;">轮播图</label>
					</td>
					<td>
						<el-upload
							class="upload-demo"
							:action="this.apiServer + '/api/v1/admin/carousel/add'"
							:auto-upload="false"
							:limit="1"
							:on-preview="handlePreview"
							:on-remove="handleRemove"
							:on-exceed="handleExceed"
							:on-success="onSuccess"
							:on-error="onError"
							:before-upload="beforeUpload"
							:file-list="fileList"
							:data="postForm"
							:headers="header"
							name="image"
							ref="addCarousel"
							list-type="picture"
						>
							<el-button size="small" type="primary">选择本地图片</el-button>
							<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
						</el-upload>
					</td>
				</tr>
			</table>

			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog>

			<el-form :model="postForm" status-icon :rules="rules" ref="postForm" label-width="100px" style="width:400px;margin-left:20px;">
				<el-form-item label="图片链接" prop="image_url">
					<el-input v-model="postForm.image_url" placeholder="图片链接"></el-input>
				</el-form-item>
				<el-form-item label="跳转链接" prop="target_url">
					<el-input v-model="postForm.target_url" placeholder="跳转链接"></el-input>
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input v-model="postForm.title" placeholder="标题"></el-input>
				</el-form-item>
				<el-form-item label="内容" prop="text">
					<el-input type="textarea" v-model="postForm.text" placeholder="内容"></el-input>
				</el-form-item>
				<el-form-item label="权重" prop="weight">
					<el-input type="number" v-model.number="postForm.weight" placeholder="权重（数字）" @mousewheel.native.prevent></el-input>
				</el-form-item>
				<el-form-item label="新页面打开" prop="new_page">
					<el-switch v-model="postForm.new_page"> </el-switch>
				</el-form-item>
				<el-button type="primary" style="margin-left:100px;" @click="saveCarousel">保存</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
import base from "@/base.js";
export default {
	data() {
		var checkEmpty = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("不能为空"));
			} else {
				callback();
			}
		};
		return {
			apiServer: base.apiServer,
			postForm: {
				image_url: "",
				target_url: "",
				title: "",
				text: "",
				weight: null,
				new_page: true
			},
			rules: {
				target_url: [{ validator: checkEmpty, trigger: "blur" }]
			},
			header: { token: this.$store.state.token },
			fileList: [],
			dialogImageUrl: "",
			dialogVisible: false
		};
	},
	methods: {
		handleRemove(file, fileList) {
			//console.log("handleRemove fileList>>", fileList);
			//console.log("handleRemove this.fileList>>", this.fileList);
		},
		handlePreview(file) {
			//console.log("handlePreview>>", file.url);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleExceed() {
			this.$message.error("只能上传一张照片，请先删除之前选择的照片");
		},
		beforeUpload(file) {
			if (this.postForm.weight == null) {
				this.postForm.weight = 0;
			}

			if (file.size > 1 * 1024 * 1024) {
				this.$message.error("图片大小不能超过1MB!");
				return false;
			} else {
				return true;
			}
		},
		// 图片上传成功
		onSuccess: function(response) {
			//console.log(response);
			if (response.code === 200) {
				this.$message({
					message: "保存成功",
					type: "success"
				});
				//执行成功1秒后跳转页面
				setTimeout(function() {
					window.location.href = "/pc/admin/carousel/list";
				}, 1000);
			} else if (response.code == -100) {
				this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "error"
				})
					.then(() => {
						//跳转到登录页面（路由跳转则滑动验证会失效）
						//this.$router.push({ path: "/login?target=" + window.location.href });
						window.location.href = "/login?target=" + window.location.href;
					})
					.catch(() => {
						this.$message({
							type: "warning",
							message: "已取消"
						});
					});
			} else {
				console.log("response>>", response);
				this.$message.error(response.message);
			}
		},
		onError: function(response) {
			console.log("response>>", response);
			this.$message.error(response.message);
		},
		saveCarousel() {
			//console.log(this.postForm);
			this.$refs.addCarousel.submit();
		}
	},
	mounted() {}
};
</script>
<style lang="scss">
/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	-o-appearance: none !important;
	-ms-appearance: none !important;
	appearance: none !important;
	margin: 0;
}
input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	-o-appearance: textfield;
	-ms-appearance: textfield;
	appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */
</style>
<style lang="scss" scoped>
.upload-demo {
	width: 297px;
}
</style>
